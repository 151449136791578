<template>
  <div class="profile client-content-padding container-fluid">
    <h1 class="full-name user-account-title basic-mb">
      {{ `${labels.profile} "${userdata.username}"` }}
    </h1>
    <div class="row profile-contents">
      <div class="address-content col-xl">
        <label class="address-label user-account-wrapper basic-mb">
          {{ labels.address }}
        </label>
        <FormulateInput class="basic-mb basic-mb-xxl-half" v-model="userdata.address.street" id="formulate-input--street"
          type="text" name="street" :placeholder="labels.street_name_house_number" />
        <FormulateInput class="basic-mb basic-mb-xxl-half" v-model="userdata.address.city" id="formulate-input--city"
          type="text" name="city" :placeholder="labels.city" />
        <FormulateInput class="basic-mb basic-mb-xxl-half" v-model="userdata.address.zip" id="formulate-input--postalcode"
          type="text" name="postalCode" :placeholder="labels.postal_code" />
        <label class="profile-picture">{{ labels.profile_picture }}</label>

        <ProfileUploadImage :btnLabel="labels.change_image" :value="userdata.avatar" @changed="onAvatarChanged" />

        <label class="safety-label user-account-wrapper basic-mb basic-mt">
          {{ labels.security }}
        </label>

        <FormulateInput class="basic-mb basic-mb-xxl-half" v-model="userdata.email" id="formulate-input--email"
          type="text" name="email" :placeholder="labels.email_address" />
        <FormulateInput class="basic-mb basic-mb-xxl-half" v-model="userdata.oldPasscode"
          id="formulate-input--oldPasscode" type="text" name="oldPasscode" :placeholder="labels.old_password" />
        <FormulateInput class="basic-mb basic-mb-xxl-half" v-model="userdata.newPasscode"
          id="formulate-input--newPasscode" type="text" name="newPasscode" :placeholder="labels.new_password" />
        <FormulateInput class="basic-mb basic-mb-xxl-half" v-model="userdata.confirmPasscode"
          id="formulate-input--confirmPasscode" type="text" name="confirmPasscode"
          :placeholder="labels.repeat_the_new_password" />

        <!-- Button  -->

        <div class="button-control d-flex justify-content-between basic-mb">
          <Button class="btn-custom btn-download" fontSize="16px" :label="labels.download_user_data"></Button>
          <Button class="btn-custom btn-upload" fontSize="16px" :label="labels.to_update" @click="updateUser"></Button>
        </div>
      </div>
      <div class="account-content col-xl">
        <label class="account-label user-account-wrapper basic-mb">
          {{ labels.account }}
        </label>
        <div class="account-name row">
          <FormulateInput class="col basic-mb basic-mb-xxl-half" v-model="userdata.name.first"
            id="formulate-input--firstname" type="text" name="firstName" :placeholder="labels.first_name" />
          <FormulateInput class="col basic-mb basic-mb-xxl-half" v-model="userdata.name.last"
            id="formulate-input--lastname" type="text" name="lastName" :placeholder="labels.last_name" />
        </div>
        <label class="birthday-label basic-mb">{{ labels.birthday }}</label>
        <div class="account-details row">
          <FormulateInput class="col basic-mb basic-mb-xxl-half" :min="userdata.date.min" :max="numberOfDays"
            v-model="userDateDay" id="formulate-input--date" type="number" name="date" />
          <FormulateInput class="col basic-mb basic-mb-xxl-half" :options="months" v-model="userDateMonth"
            id="formulate-input--month" type="select" name="month" />
          <FormulateInput class="col basic-mb basic-mb-xxl-half" :options="years" v-model="userdata.dateBirth.year"
            id="formulate-input--year" type="select" name="year" />
        </div>
        <label class="gender-label basic-mb">{{ labels.gender }}</label>
        <div class="gender-options">
          <FormulateInput v-model="userdata.gender" :options="{ 0: labels.male, 1: labels.female, 2: labels.various }"
            type="radio" />
        </div>

        <label class="description-label basic-mb">{{
          labels.description
        }}</label>

        <FormulateInput type="text-editor" error-behavior="live" validation-name="Text" v-model="userdata.description" />

        <div class="button-control basic-mb">
          <Button class="btn-custom btn-delete" :label="labels.deleting_an_account"></Button>
        </div>
      </div>
    </div>


    <div v-if="isPayableFeature" class="account-subscriptions">
      <div class="account-subscriptions-title">Active subscriptions</div>
      <div class="account-subscriptions-list">
        <div class="account-subscriptions-item" v-for="sub, key in demoData" :key="key">
          <div class="top">
            <div class="account-subscriptions-item-name">{{ sub.bundleName }}</div>
            <div class="account-subscriptions-item-price">
              <span>{{ sub.price }} </span>
              <span>{{ sub.currency }} </span>
              <span>per month</span>
            </div>
          </div>
          <div class="bottom"><button type="button" class="btn btn-primary account-subscriptions-item-button"
              data-toggle="modal" data-target=".bd-example-modal-lg" @click="openModalSubscriptions(key)">Open
              details</button>
            <div class="account-subscriptions-item-expired">
              Expired on {{ convertDate(sub.expired) }}
            </div>
          </div>


        </div>
      </div>



      <div class="modal fade bd-example-modal-lg subscriptions-popup" tabindex="-1" role="dialog"
        aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="modal-body-inner">
                <div class="modal-body-inner-header">
                  <div class="modal-body-inner-header-top">
                    <div class="modal-body-inner-header-top-name">{{ subscriptionsPopupData.bundleName }}</div>
                    <div class="modal-body-inner-header-top-price">
                      <span class="amount">{{ subscriptionsPopupData.price }} </span>
                      <span>{{ subscriptionsPopupData.currency }} </span> /
                      <span>month</span>
                    </div>
                  </div>
                  <div class="modal-body-inner-header-center">
                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modalBills"
                      @click="openBills">
                      ( Bills )
                    </button>
                  </div>
                  <div class="modal-body-inner-header-bottom">
                    <span>Note The service ts exempt from sa;es tax in accordance mth 19 (1) UStC</span>
                  </div>
                </div>
                <div class="modal-body-inner-body">
                  <div class="modal-body-inner-body-list" v-html="subscriptionsPopupData.access"></div>
                  <a href="#">subscription plan</a>
                  <a href="#">Stripe customer portal</a>
                </div>
                <div class="modal-body-inner-footer">
                  <div class="modal-body-inner-footer-subscribed-on">
                    Subscribed on: <span>{{
                      convertDate(subscriptionsPopupData.subscribedOn) }}</span>
                  </div>
                  <div class="modal-body-inner-footer-subscribed-till">
                    Subscription ends: <span>{{
                      convertDate(subscriptionsPopupData.subscribedTill) }}</span>
                  </div>
                  <div class="modal-body-inner-footer-subscribed-state">
                    State: {{ subscriptionsPopupData.state }}
                  </div>
                  <div class="modal-body-inner-footer-label">
                    If you unsubscn* you will access to the re"urces Er law
                    enforcement
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade bills-popup" style="z-index: 10001;" id="modalBills" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="modal-body-name">
                <div class="modal-body-name-name">{{ subscriptionsPopupData.bundleName }}</div>
                <a href="#" class="modal-body-name-link">( Details )</a>
              </div>
              <div class="modal-body-bills">
                <div class="modal-body-bills-bill" v-for="bill, key in subscriptionsPopupData.bills" :key="key">
                  <span>{{ convertDate(bill.from) }} - {{ convertDate(bill.to) }}</span>
                  <a href="#">Download</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="account-signed-contracts">
      <div class="account-signed-contracts-title">Signed contracts</div>
      <div class="account-signed-contracts-list">
        <div v-for="contract, key in signedContracts" :key="key" class="account-signed-contracts-list-item">
          <div class="account-signed-contracts-list-item-title">{{ contract.title }}</div>
          <div class="account-signed-contracts-list-item-text" v-html="contract.text"></div>
          <div class="account-signed-contracts-list-item-date"> {{ labels.signed }} : {{ convertDate(contract.signed) }}
          </div>
          <div class="account-signed-contracts-list-item-date"> {{ labels.date_of_effect }} : {{
            convertDate(contract.dateOfEffect) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button";
import ProfileUploadImage from "@/components/Input/ProfileUploadImage";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "Profile",
  components: { ProfileUploadImage, Button },
  data() {
    return {
      userdata: {
        email: "",
        oldPasscode: "",
        newPasscode: "",
        confirmPasscode: "",
        address: {
          city: "",
          country: "",
          state: "",
          street: "",
          zip: "",
        },
        admin_roles: [],
        name: {
          first: "",
          last: "",
          middle: "",
        },
        phone: [],
        description: "",
        gender: null,
        dateBirth: {
          day: null,
          month: null,
          year: null,
        },
        avatar: "",
        defaultLanguage: "en",
        timeCreated: "",
        username: "",
        date: {
          min: 1,
          max: 31,
        },
      },
      userDateMonth: null,
      userDateDay: null,
      demoData: [
        {
          bundleName: 'Police enforcement service NRW 1',
          price: '14.98',
          currency: 'EUR',
          expired: '2023-07-01T08:33:59',
          access: '<div>Your full access</div><ul><li>First position</li><li>Second position</li><li>Third position</li><li>Fourth position</li></ul>',
          subscribedOn: '2023-05-01T08:33:59',
          subscribedTill: '2023-07-01T08:33:59',
          state: 'Cancelled',
          bills: [
            {
              from: '2023-05-01T08:33:59',
              to: '2023-05-01T08:33:59',
            },
            {
              from: '2023-05-01T08:33:59',
              to: '2023-05-01T08:33:59',
            }
          ]
        },
        {
          bundleName: 'Police enforcement service NRW 2',
          price: '14.98',
          currency: 'EUR',
          expired: '2023-07-01T08:33:59',
          access: '<ul><li>First position</li><li>Second position</li><li>Third position</li><li>Fourth position</li></ul> <div>Your full access</div><ul><li>First position</li><li>Second position</li><li>Third position</li><li>Fourth position</li></ul> <div>Your full access</div><ul><li>First position</li><li>Second position</li><li>Third position</li><li>Fourth position</li></ul> <div>Your full access</div><ul><li>First position</li><li>Second position</li><li>Third position</li><li>Fourth position</li></ul>',
          subscribedOn: '2023-05-01T08:33:59',
          subscribedTill: '2023-07-01T08:33:59',
          state: 'Cancelled',
          bills: [
            {
              from: '2023-05-01T08:33:59',
              to: '2023-05-01T08:33:59',
            },
            {
              from: '2023-05-01T08:33:59',
              to: '2023-05-01T08:33:59',
            }
          ]
        },
        {
          bundleName: 'Police enforcement service NRW 3',
          price: '14.98',
          currency: 'EUR',
          expired: '2023-07-01T08:33:59',
          access: '<div>Your full access</div><ul><li>First position</li><li>Second position</li><li>Third position</li><li>Fourth position</li></ul> <div>Your full access</div><ul><li>First position</li><li>Second position</li><li>Third position</li><li>Fourth position</li></ul> <div>Your full access</div><ul><li>First position</li><li>Second position</li><li>Third position</li><li>Fourth position</li></ul> <div>Your full access</div><ul><li>First position</li><li>Second position</li><li>Third position</li><li>Fourth position</li></ul>',
          subscribedOn: '2023-05-01T08:33:59',
          subscribedTill: '2023-07-01T08:33:59',
          state: 'Cancelled',
          bills: [
            {
              from: '2023-05-01T08:33:59',
              to: '2023-05-01T08:33:59',
            },
            {
              from: '2023-05-01T08:33:59',
              to: '2023-05-01T08:33:59',
            }
          ]
        },
        {
          bundleName: 'Police enforcement service NRW 4',
          price: '14.98',
          currency: 'EUR',
          expired: '2023-07-01T08:33:59',
          access: 'text',
          subscribedOn: '2023-05-01T08:33:59',
          subscribedTill: '2023-07-01T08:33:59',
          state: 'Cancelled',
          bills: [
            {
              from: '2023-05-01T08:33:59',
              to: '2023-05-01T08:33:59',
            },
            {
              from: '2023-05-01T08:33:59',
              to: '2023-05-01T08:33:59',
            }
          ]
        }
      ],
      subscriptionsPopupData: {},
      signedContracts: []
    };
  },

  mounted() {
    this.loadUser();
    this.getBasic().then(res => {
      this.signedContracts = res.signedContracts
    })
  },

  watch: {
    userId() {
      this.loadUser();
    },
    singleUser(newData) {
      this.userdata = { ...this.userdata, ...newData };
    },
    userDateMonth() {
      this.userdata.dateBirth.month = this.userDateMonth;

      if (this.userDateDay > this.numberOfDays) {
        this.userDateDay = this.numberOfDays;
      }
    },
    userDateDay() {
      if (this.userDateDay > this.numberOfDays) {
        this.userDateDay = this.numberOfDays;
      }
    },
    numberOfDays() {
      if (this.userDateDay > this.numberOfDays) {
        this.userDateDay = this.numberOfDays;
      }
    },
  },

  computed: {
    ...mapGetters("helper", ["userId", "labels"]),
    ...mapGetters("user", ["singleUser"]),
    ...mapState("helper", ["features"]),
    months() {
      let arr = [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ];
      let mon = [];
      arr.forEach((element) => {
        mon.push(this.labels[element]);
      });
      return mon;
    },
    years() {
      let year = [];
      const currentYear = new Date().getFullYear();

      // Calculate the minimum and maximum allowed year
      const minYear = currentYear - 120;
      const maxYear = currentYear - 6;

      // Generate the list of years
      for (let i = maxYear; i >= minYear; i--) {
        year.push(i);
      }
      return year;
    },
    numberOfDays() {
      let month = this.userDateMonth;
      const currentYear = Number(this.userdata.dateBirth.year);
      // Create a date object for the selected month and year
      const date = new Date(currentYear, this.months.indexOf(month), 1);

      // Determine the number of days in the selected month
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    },
    isPayableFeature() {
      if (this.features?.payable) return true;

      return false;
    }
  },

  methods: {
    ...mapActions("user", ["getUser", "editUser"]),
    ...mapActions("notification", ["addNotification"]),
    ...mapActions("helper", {
      getBasic: "getBasic",
    }),
    openBills() {
      setTimeout(() => {
        if (document.querySelectorAll('.modal-backdrop').length > 1) {
          document.querySelectorAll('.modal-backdrop')[document.querySelectorAll('.modal-backdrop').length - 1].style.zIndex = 10000;
        }
      }, 100)
    },

    updateUser() {
      this.editUser({
        id: this.userId,
        payload: this.userdata,
      })
        .then((res) => {
          if (res.success) {
            this.addNotification({
              variant: "success",
              msg: ["user_updated"],
              labels: this.labels,
            });
          } else {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },

    loadUser() {
      if (!this.userId) {
        return;
      }
      if (
        Object.keys(this.singleUser).length &&
        this.singleUser._id === this.userId
      ) {
        this.userdata = { ...this.userdata, ...this.singleUser };
      } else {
        this.getUser(this.userId);
      }
    },

    onAvatarChanged(avatarUrl) {
      this.userdata.avatar = avatarUrl;
    },
    convertDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();

      return `${day}/${month}/${year}`
    },
    openModalSubscriptions(key) {
      this.subscriptionsPopupData = this.demoData[key]
    }
  },
  created() {
    if (this.labels && this.userDateMonth == null)
      this.userDateMonth = this.months[0];

    if (this.userdata.dateBirth.year == null)
      this.userdata.dateBirth.year = this.years[0];
  },

};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.profile {
  // padding: 3.125rem 6.25rem 0rem 6.25rem;
  // @media (max-width: 1599.98px) {
  //     padding: 30px 60px 0px;
  // }

  // @media (max-width: 1199.98px) {
  //     padding: 30px 30px 0px;
  // }

  .full-name {
    color: $black;
    font-weight: 500;
    line-height: 1.2;
    display: flex;
    justify-content: center;
  }

  &-contents {
    @media (max-width: 1199.98px) {
      flex-direction: column-reverse;
    }

    .account-content {
      padding: 0px 25px;

      .account-label {
        color: $black;
        line-height: 1.2;
        font-weight: 500;

        @media (min-width: 1600px) {
          margin-bottom: 25px;
        }
      }

      .account-name {
        ::v-deep {
          .formulate-input {
            &-wrapper {
              .formulate-input-element {
                max-width: 100%;

                #formulate-input--firstname,
                #formulate-input--lastname {
                  background: $white;
                  border: 1px solid $lightgray;
                  font-size: 14px;
                  padding: 10px 40px 10px 15px;
                  border-radius: 5px;
                  height: 43px;

                  &:hover,
                  &:focus {
                    border-color: $navy;
                  }
                }
              }
            }
          }
        }
      }

      .birthday-label,
      .gender-label,
      .description-label {
        font-size: 80%;
        font-weight: 400;

        @media (min-width: 1600px) {
          margin-bottom: 25px;
        }
      }

      .gender-options {
        ::v-deep {
          .formulate-input {
            &-wrapper {
              .formulate-input-element {
                display: flex;
                justify-content: space-between;

                &--group {
                  min-width: 100%;
                  margin-bottom: 12.5px;

                  .formulate-input-group-item {
                    width: 100%;
                    font-size: 18px;
                  }
                }

                label {
                  margin-bottom: 0;
                }

                .formulate-input-label {
                  font-weight: 400;
                  font-size: 14px;

                  @media (min-width: 1200px) {
                    font-size: 16px;
                  }

                  // @media (min-width: 1600px) {
                  // font-size: 18px;
                  // }
                }

                .formulate-input-element-decorator {
                  box-sizing: unset;

                  &:before {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                }

                input[type="radio"]:checked~.formulate-input-element-decorator {
                  border-color: $navy;

                  &:before {
                    background-color: $navy;
                  }
                }
              }
            }
          }
        }
      }

      .account-details {
        ::v-deep {
          .formulate-input {
            &-wrapper {
              .formulate-input-element {

                #formulate-input--date,
                #formulate-input--month,
                #formulate-input--year {
                  background: $white;
                  border: 1px solid $lightgray;
                  font-size: 14px;
                  padding: 10px 40px 10px 15px;
                  border-radius: 5px;
                  height: 43px;

                  &:hover,
                  &:focus {
                    border-color: $navy;
                  }
                }
              }
            }
          }
        }
      }

      .btn-custom {
        @include btn-custom;
        margin: 0px 0px;
        height: 46px;
        padding: 11px 30px;
        filter: none;
        width: 100%;

        @media (max-width: 1599.98px) {
          font-size: 14px;
          padding: 11px 20px;
        }
      }

      .btn-delete {

        &:hover,
        &:focus {
          background: darken($color: $navy, $amount: 5%);
        }
      }
    }

    .address-content {
      padding: 0px 25px;

      // .profile-upload-image {}

      .address-label,
      .safety-label {
        color: $black;
        line-height: 1.2;
        font-weight: 500;

        @media (min-width: 1600px) {
          margin-bottom: 25px;
        }
      }

      .profile-picture {
        font-size: 14.4px;
        margin-bottom: 25px;
      }

      .btn-custom {
        @include btn-custom;
        margin: 0px 0px;
        height: 46px;
        padding: 11px 30px;
        width: 100%;
        filter: none;

        @media (max-width: 1599.98px) {
          font-size: 14px;
          height: auto;
          padding: 11px 20px;
        }
      }

      .btn-download {
        color: $navy;
        background: transparent;
        border: 1px solid $navy;
        filter: none;
        margin-bottom: 12.5px;
        margin-right: 12.5px;

        &:hover,
        &:focus {
          color: $white;
          background: $navy;
        }
      }

      .btn-upload {
        margin-bottom: 12.5px;
        margin-left: 12.5px;

        &:hover,
        &:focus {
          background: darken($color: $navy, $amount: 5%);
        }
      }

      ::v-deep {
        .formulate-input {
          &-wrapper {
            .formulate-input-element {
              max-width: 100%;

              #formulate-input--street,
              #formulate-input--city,
              #formulate-input--postalcode {
                background: $white;
                font-size: 14px;
                padding: 10px 40px 10px 15px;
                border-radius: 5px;
                height: 43px;
              }

              input[type="text"] {
                background-color: $white;

                &:hover,
                &:focus {
                  border-color: $navy;
                }
              }
            }
          }
        }
      }
    }
  }

  .account-subscriptions {
    &-title {
      font-size: 25px;
      font-weight: 600;
    }

    &-list {}

    &-item {
      display: flex;
      column-gap: 100px;
      row-gap: 20px;
      align-items: center;
      margin: 15px 0;
      font-weight: 500;

      @media (max-width:991px) {
        flex-wrap: wrap;
        margin-bottom: 40px;
      }

      .top,
      .bottom {
        display: flex;
        justify-content: space-between;
        width: 50%;

        @media (max-width:991px) {
          width: 80%;
        }

        @media (max-width:767px) {
          width: 100%;
        }

        gap: 50px;
        align-items: center;
      }

      &-button {
        background-color: $navy;
        border: none;
        color: $white;
        border-radius: 3px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease-in-out all;
        border: 2px solid $navy;

        &:hover {
          background-color: transparent;
          border: 2px solid $navy;
          color: $black;
        }
      }
    }

    .subscriptions-popup {
      .modal-body {
        &-inner {
          padding: 0 20px;

          &-header {
            border-bottom: 2px solid $gray_btn;
            padding-bottom: 5px;

            &-top {
              display: flex;
              justify-content: space-between;
              align-items: center;

              @media (max-width: 991px) {
                flex-direction: column;
                align-items: flex-start;
              }

              &-name {
                font-size: 20px;
                font-weight: 600;
              }

              &-price {
                color: $navy;
                font-size: 20px;
                font-weight: 600;

                .amount {
                  font-weight: 700;
                }
              }
            }

            &-center {
              display: flex;
              justify-content: flex-end;
              font-size: 20px;

              button {
                color: $navy;
                font-weight: 600;
                background-color: transparent;
                border: none;

                &:hover {
                  text-decoration: none;
                }

                position: relative;

                &::after {
                  content: '';
                  width: 60%;
                  height: 2px;
                  background-color: $navy;
                  bottom: 0px;
                  left: 20%;
                  position: absolute;
                }
              }
            }

            &-bottom {
              display: flex;
              justify-content: flex-end;
              color: $gray_btn;
              margin-top: 15px;
            }
          }

          &-body {
            padding: 25px 0;
            border-bottom: 2px solid $gray_btn;
            max-height: 350px;
            overflow: auto;

            a {
              display: block;
              width: fit-content;
              color: $navy;
              position: relative;

              &:last-child {
                margin-top: 10px;
              }

              &::after {
                content: '';
                width: 100%;
                height: 2px;
                background-color: $navy;
                bottom: -3px;
                left: 0;
                position: absolute;
              }
            }
          }

          &-footer {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 25px 0;

            &-subscribed-on,
            &-subscribed-till {
              span {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }

  .account-signed-contracts {
    &-title {
      font-size: 25px;
      font-weight: 600;
    }

    &-list {
      margin: 15px 0;

      &-item {
        border-top: 1px solid $gray_btn;
        padding-top: 15px;

        &:first-child {
          border-top: none;
          padding-top: 0;
        }

        margin-bottom: 20px;

        &-title {
          font-weight: 500;
        }

        &-text {}

        &-date {
          margin-top: 5px;
          font-size: 14px;
          color: $gray_btn;
        }
      }
    }
  }

  .bills-popup {
    .modal-header {
      border: none;
    }

    .modal-body {
      padding-top: 0;
      padding-left: 40px;
      padding-right: 40px;

      &-name {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        border-bottom: 2px solid $gray_btn;
        padding-bottom: 20px;

        &-name {
          font-size: 20px;
          font-weight: 600;
        }

        &-link {
          color: $navy;
          font-weight: 600;
          background-color: transparent;
          border: none;

          &:hover {
            text-decoration: none;
          }

          position: relative;

          &::after {
            content: '';
            width: 90%;
            height: 2px;
            background-color: $navy;
            bottom: -3px;
            left: 5%;
            position: absolute;
          }
        }
      }

      &-bills {
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        &-bill {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          flex-wrap: wrap;

          span {
            font-weight: 700;
          }

          a {
            background-color: $navy;
            border: none;
            color: $white;
            border-radius: 3px;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s ease-in-out all;
            border: 2px solid $navy;

            &:hover {
              background-color: transparent;
              border: 2px solid $navy;
              color: $black;
            }
          }
        }
      }
    }
  }
}</style>
